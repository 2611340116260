import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: "/auth",
  realm: "text2test",
  clientId: "text2test-client",
};

if (!window.keycloak) {
  window.keycloak = new Keycloak(keycloakConfig);
}

const initKeycloak = (onAuthenticatedCallback) => {
  if (window.keycloak.authenticated) {
    onAuthenticatedCallback();
    return;
  }
  window.keycloak
    .init({ onLoad: "login-required" })
    .then(async (authenticated) => {
      if (authenticated) {
        onAuthenticatedCallback();
      } else {
        await window.keycloak.login();
      }
    })
    .catch((error) => {
      console.error("Failed to initialize Keycloak", error);
    });
};

const logout = async () => {
  console.log("Logging out");
  await window.keycloak.logout();
};

const getUserInfo = () => {
  console.log("Getting user info");
  return window.keycloak.tokenParsed;
};

const isUserLoggedIn = () => {
  console.log("Checking if user is logged in");
  return !!window.keycloak.authenticated;
};

const getToken = () => {
  return window.keycloak.token;
};

const refreshToken = () => {
  return window.keycloak.updateToken();
};

export {
  initKeycloak,
  logout,
  getUserInfo,
  isUserLoggedIn,
  getToken,
  refreshToken,
};
