import "zone.js";
import {
  initGlobalState,
  registerMicroApps,
  runAfterFirstMounted,
  setDefaultMountApp,
  start,
} from "qiankun";
import { initKeycloak } from "keycloak-auth";

initKeycloak(() => {
  console.log("Keycloak initialized");
  console.log("Main App: Registering micro apps");
  start();
});

registerMicroApps(
  [
    {
      name: "app",
      entry:
        process.env.MICRO_APP_ENV === "production"
          ? "/app/"
          : "http://localhost:8080/app/",
      container: "#container",
      activeRule: "/app",
    },
    {
      name: "appium-connector",
      entry:
        process.env.MICRO_APP_ENV === "production"
          ? "/appium-connector/"
          : "http://localhost:1234/appium-connector/",
      container: "#container",
      activeRule: "/appium-connector",
    },
    {
      name: "report",
      entry:
        process.env.MICRO_APP_ENV === "production"
          ? "/report/"
          : "http://localhost:3000/report/",
      container: "#container",
      activeRule: "/report",
    },
  ],
  {
    beforeLoad: [
      (app) => {
        console.log("[LifeCycle] before load %c%s", "color: green;", app.name);
      },
    ],
    beforeMount: [
      (app) => {
        console.log("[LifeCycle] before mount %c%s", "color: green;", app.name);
      },
    ],
    afterUnmount: [
      (app) => {
        console.log(
          "[LifeCycle] after unmount %c%s",
          "color: green;",
          app.name
        );
      },
    ],
  }
);

const { onGlobalStateChange, setGlobalState } = initGlobalState({
  user: "qiankun",
});

onGlobalStateChange((value, prev) =>
  console.log("[onGlobalStateChange - master]:", value, prev)
);

setGlobalState({
  ignore: "master",
  user: {
    name: "master",
  },
});

setDefaultMountApp("/app");

runAfterFirstMounted(() => {
  console.log("[MainApp] first app mounted");
});
